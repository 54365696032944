import "objectFitPolyfill";
import Headroom from "headroom.js";

require('imports-loader?window.jQuery=jquery!../../node_modules/jquery.waitforimages/dist/jquery.waitforimages.min');
require('imports-loader?window.jQuery=jquery!../libs/prettyEmbed/jquery.prettyembed.js');

$(document).ready( function () {
    objectFitPolyfill();
    initMobileMenu();
    $().prettyEmbed({ useFitVids: false });
});

var headerBottom = document.getElementById('head-bottom');
var headroom = new Headroom(headerBottom);

headroom.init();

function initMobileMenu() {
    var menuOverlay = document.getElementById('mmenu-overlay');
    var menuBtn = document.getElementById('mobile-btn');
    var menu = document.getElementById('mobile-menu');

    menuBtn.addEventListener('click', function (event) {
        event.preventDefault();

        $(menuOverlay).fadeIn();
        menu.classList.add('is-active');
    });

    menuOverlay.addEventListener('click', function (event) {
        event.preventDefault();

        $(menuBtn).find('.hamburger').removeClass('is-active');
        menu.classList.remove('is-active');
        $(menuOverlay).fadeOut();
    })
}